import { Container, Content, FeaturedBackgroundImage } from "@components"
import {
  AffirmMembership,
  ImageSlider,
  MapEmbed,
  Newsletter,
  PopupBanner,
  Sponsors,
} from "@features"
import { PortableText } from "@portabletext/react"
import { graphql } from "gatsby"
import React, { useState } from "react"
import Seo from "react-seo-component"
import { useSiteMetadata } from "../hooks/use-site-metadata"
// import PopupBanner from "../components/PopupBanner"
// import post from "../../../sanity-blog/schemas/blogPost"
import { PerfectVenueForm } from "@/components/features/PerfectVenueForm/PerfectVenueForm"
import { usePortableTextComponents } from "../hooks"

// const debug = process.env.DEBUG
const debug = false

export default function Page({ location, data }) {
  const {
    title,
    slug: { current },
    heroImage: {
      // alt,
      // caption,
      heroImagePosition,
      heroImageHeight,
      heroImageHeightSm,
      heroImageHeightMd,
      heroImageHeightLg,
    },
  } = data.page
  const slug = current
  const { page } = data

  if (debug) {
    console.log("[page template] slug:", slug)
    console.log("data:", data)
    console.log("[page template] page:", page)
  }

  const portableComponents = usePortableTextComponents(slug || "")

  const {
    allSanityPopup: { edges: popupData },
    allShopifyProduct: { edges: shopifyProducts },
  } = data
  // console.log("[Page template] popupData:", popupData)
  const activePopupExists = popupData.find(popup => popup.node.popupEnabled)
  // console.log("[Page template] activePopupExists:", activePopupExists)

  // const getPublishedDate = date => {
  //   const tempDate = new Date(date)
  //   return tempDate.toLocaleDateString()
  // }

  const [popupOpen, setPopupOpen] = useState(true)

  const {
    title: siteTitle,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata()

  const { pathname } = location
  if (debug) {
    console.log("location:", location)
    console.log("location.pathname:", pathname)
  }

  const featureImage = page?.heroImage
    ? page?.heroImage?.asset?.gatsbyImageData
    : null

  return (
    <>
      <Seo
        title={title}
        titleTemplate={siteTitle}
        description={title}
        pathname={`${siteUrl}/${slug}`}
        article={true}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        author={authorName}
        modifiedDate={new Date(Date.now()).toISOString()}
      />

      <Container pathname={pathname}>
        {activePopupExists && popupOpen && (
          <PopupBanner
            setOpenPopup={setPopupOpen}
            popupData={activePopupExists}
          />
        )}
        {/* <Content> */}
        {featureImage && (
          <FeaturedBackgroundImage
            pathname={pathname}
            // image={featureImage ? featureImage : null}
            image={featureImage ? featureImage : null}
            title={title}
            opacity={0.5}
            heroImagePosition={heroImagePosition}
            heroImageHeight={heroImageHeight}
            heroImageHeightSm={heroImageHeightSm}
            heroImageHeightMd={heroImageHeightMd}
            heroImageHeightLg={heroImageHeightLg}
          />
        )}
        <Content pathname={pathname}>
          {!featureImage && <h1>{title}</h1>}
          {slug === "join" && <AffirmMembership products={shopifyProducts} />}
          {slug === "events" && (
            <>
              <ImageSlider images="event" />
              <br />
            </>
          )}
          {slug === "recreate" && (
            <>
              <ImageSlider images="recreate" />
              <br />
            </>
          )}
          {slug === "fitnessbenefit" && (
            <>
              <ImageSlider images="fitnessBenefits" />
              <br />
            </>
          )}
          {/* <MDXRenderer>{body}</MDXRenderer> */}
          {/* {slug === "contact/" && <Newsletter />} */}
          {/* {slug === "contact/" && (
            <>
              <br />
              <div id="newsletter">
                <Newsletter />
              </div>
            </>
          )} */}
          {/* {slug === "contact/" && (
            <>
              <br />
              <Newsletter2 />
            </>
          )} */}
          {/* {slug === "reserve/" && (
            <>
              <br />
              <br />
              <IFrame
                id="form-iframe"
                src="https://app.courtreserve.com/Online/Public/EmbedCode/7429/22872"
                scrolling="yes"
                styledComponent="StyledIframe"
              />
            </>
          )} */}

          {/* </Content> */}
          {/* <StyledPost> */}
          {/* <div className="main"> */}
          {/* <div>
          <p>Slug is: {current}</p>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div> */}
          {/* {data.allSanityPost.edges.map((node) => ( */}

          <div key={page.id} className="page">
            {/* {console.log("page.body:", page.body)} */}
            {/* <h1>{page.title ?? "no title"}</h1> */}
            {/* <p>{page.slug.current}</p> */}
            {/* <p>{page.publishedAt}</p> */}
            {/* <p>{getPublishedDate(page.publishedAt)}</p> */}
            {/* {page.heroImage && <Image {...page.heroImage} alt="two players" />} */}

            {/* {page.heroImage && (
                  <SanityImage
                    {...page.heroImage}
                    width={500}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="two players"
                  />
                )} */}
            <PortableText
              value={page._rawBody}
              // components={myPortableTextComponents}
              components={portableComponents}
            />
            {slug === "contact" && (
              <>
                <br />
                <div id="newsletter">
                  <Newsletter />
                </div>
                <br />
                <MapEmbed src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2038.9955965683773!2d-122.570825099954!3d45.399549397190285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549575632710fba3%3A0x41d3449fadcd3005!2sRECS!5e0!3m2!1sen!2sus!4v1733807287237!5m2!1sen!2sus" />
              </>
            )}
            {slug === "events" && (
              <>
                <br />
                {/* <EventsForm /> */}
                <PerfectVenueForm />
              </>
            )}
            {/* <BlockContent blocks={page.body} /> */}
            {/* {console.log("page.heroImage:", page.heroImage)} */}

            {/* <SanityImage {...page.heroImage} /> */}

            {/* <pre>{JSON.stringify(page, null, 2)}</pre> */}
          </div>
          {/* </div> */}
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          {/* </StyledPost> */}
          {slug === "sponsors" && <Sponsors />}
        </Content>
      </Container>
    </>
  )
}

// TODO Fix Shopify products being included on every page query
export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      heroImage {
        ...ImageWithPreview
        asset {
          gatsbyImageData
        }
        alt
        caption
        heroImageHeight
        heroImageHeightLg
        heroImageHeightMd
        heroImageHeightSm
        heroImagePosition
      }
      title
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      _type
      showInFooter
      showInNav
    }
    allSanityCta {
      edges {
        node {
          id
        }
      }
    }
    allSanityInstructor {
      edges {
        node {
          id
          image {
            ...ImageWithPreview
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
    allSanityJob {
      edges {
        node {
          id
        }
      }
    }
    allSanityPopup {
      edges {
        node {
          ...SanityPopup
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          createdAt
          status
          shopifyId
          storefrontId
          totalInventory
          productType
          onlineStoreUrl
          variants {
            id
            displayName
            shopifyId
            price
          }
          metafield(
            namespace: "import_information"
            key: "trade_item_description"
          ) {
            id
            value
            namespace
            key
            shopifyId
            type
          }
          metafields {
            id
            value
            key
            legacyResourceId
            namespace
            ownerType
            shopifyId
            type
          }
        }
      }
    }
  }
`

// const StyledPost = styled.div`
//   .main {
//     /* margin: 25px; */
//     /* width: 750px; */
//     width: 100%;
//     max-width: calc(100vw - 50px);
//   }

//   p {
//     /* background: rebeccapurple;
//     line-height: 45px; */
//     margin: 0.5em 0 1em;
//   }
// `
